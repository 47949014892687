// import axios from "axios"
import axios from "../../constants/axios"
import endpoints from "../../constants/endpoints"

export const FETCH_COMPANY_DETAILS_DATA = async (dispatch, type) => {
  try {
    const res = await axios.get(
      process.env.GATSBY_BASE_URL + endpoints.COMPANY_PROFILE_FETCH
    )
    if (res?.status === 200 && res?.data) {
      dispatch({ type: type ?? "COMPANY_DATA", payload: res.data })
    }
    return
  } catch (error) {}
}
